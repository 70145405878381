import { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FetchApi } from "../../../../../utils/fetch";
import { AlertSuccess, AlertError } from "../../../../../components/alert";
import { validateModelDni, validateModelPasport } from "./validationSchema";

const EditarIntegrante = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState({});

  useLayoutEffect(() => {
    const data = location.state && location.state.data;
    if (!data) {
      history.push("/usuarios/grupo-familiar/lista");
    }
  }, []);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const customer = location.state && location.state.data;
    const data = {
      idCustomer: customer.idCustomerPrincipal,
      familyMember: {
        id: customer.id,
        familygroupId: customer.idMiembro,
        relationshipid: customer.relationshipId,
        firstName: customer.nombres,
        lastName: customer.apellidos,
        birthday: customer.fechaNacimiento,
        dni: values.numeroDocumento,
        dniType: values.dniType,
        genderid: customer.genderId,
        credential: customer.credencial,
        fileDocLegal: "",
        statusCode: customer.idEstado,
      },
    };

    FetchApi(`customers/api/v1/familiargroup/updatefamilymember`, "post", data)
      .then((res) => {
        const data = res?.response?.data;
        if (data?.Message?.Generic?.includes("DNI duplicado")) {
          AlertError("El documento ya se encuentra registrado.");
          return;
        }
        if (res?.code == "ERR_BAD_RESPONSE") {
          AlertError("No se pudo actualizar, intentelo de nuevo.");
          return;
        }

        AlertSuccess("Actualizado correctamente.");
      })
      .catch(() => {
        AlertError("Error al actualizar.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: { ...location?.state?.data },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formik.values?.dniType == "0") {
      setValidationSchema(validateModelDni);
    } else {
      setValidationSchema(validateModelPasport);
    }
  }, [formik.values?.dniType]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>MODIFICACION DE INTEGRANTE GF</Card.Header>
            <Card.Body>
              <Row className="">
                <Col md="6">
                  <label>Nombre completo </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-custom input-group-text"
                        id="basic-addon1"
                      >
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="nombreCompleto"
                      value={formik.values?.nombreCompleto}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>Documento</label>
                  <div className="input-group mb-3">
                    <select
                      name="dniType"
                      onChange={formik.handleChange}
                      value={formik.values?.dniType}
                      class="form-control"
                      defaultValue={formik.values?.dniType}
                    >
                      <option value="0">DNI</option>
                      <option value="2">Pasaporte</option>
                      <option value="1" disabled>
                        Cédula
                      </option>
                      <option value="3" disabled>
                        Libreta Cívica
                      </option>
                    </select>
                  </div>
                  <div className="text-danger">
                    {formik.errors?.tipoDocumento}
                  </div>
                </Col>

                <Col md="3" className="mt-31">
                  <input
                    type="text"
                    name="numeroDocumento"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values?.numeroDocumento}
                    className="form-control"
                  />
                  <div className="text-danger">
                    {formik.errors?.numeroDocumento}
                  </div>
                </Col>

                <Col md="3">
                  <label>Credencial</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="credencial"
                      value={formik.values?.credencial}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Fecha de nacimiento</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder="dd-mm-yyyy"
                      type="text"
                      name="fechaNacimiento"
                      value={formik.values?.fechaNacimiento}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Parentesco </label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="parentesco"
                      value={formik.values?.parentesco}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Fecha de solicitud</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder="dd-mm-yyyy"
                      type="text"
                      name="Member.birthday"
                      value={formik.values?.fechaSolicitud}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Estado</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="estado"
                      value={formik.values?.estado}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>Observación</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="observacion"
                      value={formik.values?.observacion}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>Responsable</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="Member.firstName"
                      value={formik.values?.responsable}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>IDTEMP</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="Member.firstName"
                      value={formik.values?.idTemp}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>IDSAP</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="Member.firstName"
                      value={formik.values?.idSap}
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="2" className="mt-31">
                  <Button
                    type="button"
                    onClick={() => formik.handleSubmit()}
                    disabled={isLoading}
                  >
                    <i className="fa fa-save mr-2"></i>Guardar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default EditarIntegrante;

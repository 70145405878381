/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button, Table } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { loadingAction } from "../../../redux/actions";
import { catchError, FetchApi, fetchLogs } from "../../../utils/fetch";
import "./modificar-usuario.scss";
import { validateModel } from "./validationSchema";
import { Formik } from "formik";
import { AlertError, AlertInfo, AlertSuccess } from "../../../components/alert";
import Swal from "sweetalert2";
import axios from "axios";

function ModificarUsuario({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({});
  const [planSecurity, setPlanSecurity] = React.useState(null);
  const history = useHistory();
  const user = useSelector((state) => state.user.data);
  const { id } = useParams();

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await FetchApi(
        `customers/Api/v1/User/GetMember`,
        "post",
        { idCustomer: id }
      );
      // Cambiar el formato de la fecha en el objeto PhoneHistory
      data.data.PhoneHistory.forEach((item) => {
        const originalDate = new Date(item.changeDate);
        const formattedDate = originalDate
          .toISOString()
          .replace("T", " ")
          .slice(0, -8);
        item.changeDate = formattedDate;
      });
      data.data.Member.birthday = data.data.Member.birthday
        .split("-")
        .reverse()
        .join("-");
      setPlanSecurity(data.data.PlanSecurity);
      setState(data.data);
      isLoadingAction(false);
      if (
        data?.data?.Member?.dniType === "3" ||
        data?.data?.Member?.dniType === "1"
      ) {
        AlertInfo(
          "Tipo de documento inválido, debe actualizarlo a DNI o Pasaporte"
        );
      }
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onEnviarEmail = async (tipo) => {
    let tipoMail = "validación";
    let typeEmail = "EMAIL_BODY_NEW_USERS";
    if (tipo === "desbloqueo") {
      tipoMail = "desbloqueo";
      typeEmail = "EMAIL_BODY_BLOCKPASSWORD";
    }
    isLoadingAction(true);
    try {
      if (state?.Member?.totalLogin >= 2) {
        const { data } = await FetchApi(
          "customers/Api/v1/Account/PassRecovery",
          "POST",
          { email: state?.Member?.email, pshb: false, typeEmail }
        );
        isLoadingAction(false);
        AlertInfo(data.message);
      } else if (state?.Member?.accessLevel == 0) {
        const { data } = await FetchApi(
          "customers/Api/v1/RegisterVerificationResetPin",
          "POST",
          { email: state?.Member?.email, pshb: false }
        );
        isLoadingAction(false);
        AlertInfo(data.message);
      }
      fetchLogs({
        idLogType: 1,
        idType: tipo === "desbloqueo" ? 6 : 16,
        description:
          "El administrador " +
          user.apellido +
          " " +
          user.nombre +
          "(" +
          user.email +
          ")" +
          " ha enviado el mail de " +
          tipoMail +
          " de cuenta al usuario " +
          state.Member.lastName +
          " " +
          state.Member.firstName +
          ".",
      });
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function BotonDeEnviarEmail(values) {
    if (state) {
      if (state.Member) {
        if (state?.Member?.totalLogin >= 2)
          return (
            <Col md="3" className="mt-31">
              <Button onClick={() => onEnviarEmail("desbloqueo")}>
                Enviar Mail Desbloqueo
              </Button>
            </Col>
          );
        else if (state?.Member?.accessLevel == 0) {
          //NONE
          return (
            <Col md="3" className="mt-31">
              <Button onClick={() => onEnviarEmail("validacion")}>
                Enviar Mail Validación
              </Button>
            </Col>
          );
        } else return null;
      } else return null;
    } else return null;
  }

  async function buscarPlanes(cobertura) {
    try {
      const { data } = await FetchApi(
        `customers/api/v1/user/GetSocialSecurityPlan?socialSecurity=${cobertura}`,
        "get",
        null
      );
      setPlanSecurity(data.data.SocialSecurityPlan);
    } catch (error) {
      console.error("Error al buscar planes:", error);
    }
  }

  function compararObjetos(objetoAntiguo, objetoNuevo) {
    const diccionario = {
      accessLevel: "Permiso de acceso",
      birthday: "Fecha de nacimiento",
      cellCompany: "Compañia",
      dni: "Documento",
      dniType: "Documento",
      email: "Email",
      familyMembers: "",
      firstName: "Nombre",
      idCUIT: "",
      idCustomer: "",
      idSap: "",
      idTemp: "",
      ivaTax: "",
      lastBadLogin: "",
      lastName: "Apellido",
      mobile: "Celular",
      numbers: "",
      password: "Contraseña",
      phone: "Fijo",
      pinCode: "",
      pinCodeExpirationDate: "",
      registrationDate: "",
      sectorLevel: "Área de acceso",
      sex: "Genero",
      socialSecurity: "Cobertura",
      socialSecurityNumber: "N° afiliado",
      socialSecurityPlan: "Plan",
    };
    const atributosCambiados = {};
    for (const clave in objetoNuevo) {
      if (objetoNuevo.hasOwnProperty(clave)) {
        if (objetoAntiguo[clave] !== objetoNuevo[clave]) {
          atributosCambiados[diccionario[clave]] = objetoNuevo[clave];
        }
      }
    }
    const formatoCampoValor = Object.entries(atributosCambiados)
      .map(([campo, valor]) => `${campo}: ${valor}`)
      .join(", ");
    return formatoCampoValor;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={state}
        enableReinitialize
        validationSchema={validateModel(user)}
        onSubmit={async (values, { setSubmitting }) => {
          //submit(values);
          if (values.Member.dniType === "3" || values.MemberdniType === "1") {
            AlertInfo(
              "Tipo de documento inválido, debe actualizarlo a DNI o Pasaporte"
            );
          } else {
            isLoadingAction(true);
            values.Member.accessLevel = parseInt(values.Member.accessLevel);
            values.Member.sectorLevel = parseInt(values.Member.sectorLevel);
            values.Member.birthday = values.Member.birthday
              .split("-")
              .reverse()
              .join("-");

            let previousValues = state;
            previousValues.Member.birthday = state.Member.birthday
              .split("-")
              .reverse()
              .join("-");

            if (
              values.Member.accessLevel == 100 &&
              previousValues.Member.accessLevel != 100
            ) {
              await axios({
                url:
                  process.env.REACT_APP_BASE_URL_GW_API +
                  "/customers/api/v1/account/accountdeletion",
                method: "POST",
                data: {
                  email: previousValues.Member.email,
                  typeEmail: "EMAIL_BODY_ACCOUNT_DELETED",
                  fromCMS: true,
                },
              })
                .then((res) => {
                  if (!res.data.error) {
                    Swal.fire({
                      allowOutsideClick: false,
                      // title:
                      //   '<h5 style="color: #224d7c">Cuenta desactivada exitosamente</h5>',
                      showDenyButton: false,
                      showCancelButton: false,
                      color: "#224d7c",
                      confirmButtonColor: "#224d7c",
                      confirmButtonText: "Continuar",
                      text: "Se envio un mail a la casilla del usuario confirmando la desactivación de la cuenta.",
                      // denyButtonText: `Don't save`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        AlertSuccess("Página actualizada con éxito");
                        history.push("/usuarios/pacientes/lista-pacientes");
                        isLoadingAction(false);
                      }
                    });
                  } else {
                    AlertInfo(
                      "Ha ocurrido un error en la desactivación, espera unos momentos y vuelve a intentarlo."
                    );
                    isLoadingAction(false);
                  }
                })
                .catch((error) => {
                  AlertInfo(
                    "Ha ocurrido un error en la desactivación, espera unos momentos y vuelve a intentarlo."
                  );
                  isLoadingAction(false);
                });
            } else {
              if (
                values.Member.accessLevel == 1 &&
                previousValues.Member.accessLevel != 1
              ) {
                await axios({
                  url:
                    process.env.REACT_APP_BASE_URL_GW_API +
                    "/customers/api/v1/account/accountRestoration",
                  method: "POST",
                  data: {
                    email: previousValues.Member.email,
                    typeEmail: "EMAIL_BODY_ACCOUNT_ACTIVATED",
                    fromCMS: true,
                  },
                })
                  .then((res) => {
                    if (!res.data.error) {
                      Swal.fire({
                        allowOutsideClick: false,
                        showDenyButton: false,
                        showCancelButton: false,
                        color: "#224d7c",
                        confirmButtonColor: "#224d7c",
                        confirmButtonText: "Continuar",
                        text: "Se envio un mail a la casilla del usuario confirmando la activación de la cuenta.",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          AlertSuccess("Página actualizada con éxito");
                          history.push("/usuarios/pacientes/lista-pacientes");
                          isLoadingAction(false);
                        }
                      });
                    } else {
                      AlertInfo(
                        "Ha ocurrido un error en la activación, espera unos momentos y vuelve a intentarlo."
                      );
                      isLoadingAction(false);
                    }
                  })
                  .catch((error) => {
                    AlertInfo(
                      "Ha ocurrido un error en la activación, espera unos momentos y vuelve a intentarlo."
                    );
                    isLoadingAction(false);
                  });
              } else {
                if (
                  values.Member.accessLevel == 100 &&
                  previousValues.Member.accessLevel == 100
                ) {
                  AlertInfo(
                    "Usuario desactivado previamente, cambie el permiso de acceso."
                  );
                } else {
                  FetchApi(`customers/Api/v1/updatepatientcms`, "put", {
                    ...values.Member,
                  })
                    .then((response) => {
                      const isDelete =
                        state.Member.accessLevel != values.Member.accessLevel &&
                        values.Member.accessLevel == 100;
                      if (isDelete) {
                        fetchLogs({
                          idLogType: 2,
                          idType: 2,
                          description: `El administrador ${user.email} a dado de baja a ${values.Member.email}`,
                        });
                      }
                      fetchLogs({
                        idLogType: 2,
                        idType: 3,
                        description:
                          "El administrador " +
                          user.apellido +
                          " " +
                          user.nombre +
                          " (" +
                          user.email +
                          ")" +
                          " ha cambiado los datos del usuario " +
                          response.data.data.customer.lastName +
                          " " +
                          response.data.data.customer.firstName +
                          ". " +
                          compararObjetos(
                            previousValues.Member,
                            response.data.data.customer
                          ),
                      });
                      AlertSuccess("Página actualizada con éxito");
                      history.push("/usuarios/pacientes/lista-pacientes");
                    })
                    .catch((error) => {
                      AlertError("Los datos no pudieron ser cambiados");
                    })
                    .finally((fin) => {
                      isLoadingAction(false);
                    });
                }
              }
              isLoadingAction(false);
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>MODIFICACION DE USUARIO</Card.Header>
                  <Card.Body>
                    <Row className="">
                      <Col md="6">
                        <label>Apellido </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-user"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            type="text"
                            name="Member.lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.Member?.lastName}
                            className="form-control"
                          />
                        </div>
                        {touched?.Member?.lastName && (
                          <div className="text-danger">
                            {errors?.Member?.lastName}
                          </div>
                        )}
                      </Col>

                      <Col md="6">
                        <label>Nombre completo </label>
                        <div className="input-group mb-3">
                          <input
                            placeholder=""
                            onBlur={handleBlur}
                            type="text"
                            name="Member.firstName"
                            onChange={handleChange}
                            value={values?.Member?.firstName}
                            className="form-control"
                          />
                        </div>
                        {touched?.Member?.firstName && (
                          <div className="text-danger">
                            {errors?.Member?.firstName}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md="6">
                        <label>Email </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-at"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            type="text"
                            name="Member.email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.Member?.email}
                            className="form-control"
                          />
                        </div>
                        {touched?.Member?.email && (
                          <div className="text-danger">
                            {errors?.Member?.email}
                          </div>
                        )}
                      </Col>

                      <Col md="6">
                        <label>Genero</label>
                        <div className="input-group mb-3">
                          <select
                            class="form-control"
                            name="Member.sex"
                            onChange={handleChange}
                            value={values?.Member?.sex}
                          >
                            <option value={"H"}>Masculino</option>
                            <option value={"M"}>Femenino</option>
                          </select>
                        </div>
                        <div className="text-danger">{errors?.Member?.sex}</div>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md="2">
                        <label>Documento</label>
                        <div className="input-group mb-3">
                          <select
                            name="Member.dniType"
                            onChange={handleChange}
                            value={values?.Member?.dniType}
                            class="form-control"
                          >
                            <option value="0">DNI</option>
                            <option value="2">Pasaporte</option>
                            <option value="1" disabled>
                              Cédula
                            </option>
                            <option value="3" disabled>
                              Libreta Cívica
                            </option>
                          </select>
                        </div>
                        <div className="text-danger">
                          {errors?.Member?.dniType}
                        </div>
                      </Col>

                      <Col md="2" className="mt-31">
                        <input
                          type="text"
                          name="Member.dni"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.Member?.dni}
                          className="form-control"
                        />
                        {touched?.Member?.dni && (
                          <div className="text-danger">
                            {errors?.Member?.dni}
                          </div>
                        )}
                      </Col>

                      <Col md="2">
                        <label>Fecha de nacimiento</label>
                        <div className="input-group mb-3">
                          <input
                            placeholder="dd-mm-yyyy"
                            type="date"
                            name="Member.birthday"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.Member?.birthday}
                            className="form-control"
                          />
                        </div>
                        {touched?.Member?.birthday && (
                          <div className="text-danger">
                            {errors?.Member?.birthday}
                          </div>
                        )}
                      </Col>

                      <Col md="2">
                        <label>Compañia</label>
                        <div className="input-group mb-3">
                          <select
                            class="form-control"
                            name="Member.cellCompany"
                            onChange={handleChange}
                            value={values?.Member?.cellCompany}
                          >
                            <option>Seleccione</option>
                            <option value="0">Personal</option>
                            <option value="1">Movistar</option>
                            <option value="2">Claro</option>
                            <option value="3">Nextel</option>
                          </select>
                        </div>
                        <div className="text-danger">
                          {errors?.Member?.cellCompany}
                        </div>
                      </Col>

                      <Col md="2">
                        <label>Número de celular</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-mobile"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            name="Member.mobile"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.Member?.mobile}
                            type="text"
                            className="form-control"
                          />
                        </div>
                        {touched?.Member?.mobile && (
                          <div className="text-danger">
                            {errors?.Member?.mobile}
                          </div>
                        )}
                      </Col>

                      <Col md="2">
                        <label>Número de télefono</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-phone"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            name="Member.phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.Member?.phone}
                            type="text"
                            className="form-control"
                          />
                        </div>
                        {touched?.Member?.phone && (
                          <div className="text-danger">
                            {errors?.Member?.phone}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <label>Cobertura</label>
                        <div className="input-group mb-3">
                          <select
                            class="form-control"
                            disabled={user.role.id !== 7}
                            name="Member.socialSecurity"
                            onChange={(e) => {
                              buscarPlanes(e.target.value);
                              handleChange(e);
                              setFieldValue("Member.socialSecurityPlan", "");
                            }}
                            value={values?.Member?.socialSecurity}
                          >
                            {values?.SocialSecurities?.map((item, indice) => (
                              <option key={indice} value={item.codigo}>
                                {item.descripcion}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="text-danger">{errors?.Member?.socialSecurity}</div>
                      </Col>
                      <Col md="3">
                        <label>Plan</label>
                        <div className="input-group mb-3">
                          <select
                            class="form-control"
                            disabled={user.role.id !== 7}
                            name="Member.socialSecurityPlan"
                            onChange={handleChange}
                            value={values?.Member?.socialSecurityPlan}
                          >
                            {planSecurity &&
                              planSecurity?.map((item, indice) => (
                                <option key={indice} value={item.codigo}>
                                  {item.descripcion}
                                </option>
                              ))}
                          </select>
                        </div>
                        {touched?.Member?.socialSecurityPlan && errors?.Member?.socialSecurityPlan && (
                          <div className="text-danger">
                            {errors?.Member?.socialSecurityPlan}
                          </div>
                        )}
                      </Col>

                      <Col md="2">
                        <label>Número de afiliado</label>
                        <div className="input-group mb-3">
                          <input
                            placeholder=""
                            disabled={user.role.id !== 7}
                            name="Member.socialSecurityNumber"
                            onChange={handleChange}
                            value={values?.Member?.socialSecurityNumber}
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="text-danger">{errors?.Member?.socialSecurityNumber}</div>
                      </Col>

                      <Col md="2">
                        <label>Cant. grupo familiar</label>
                        <div className="input-group mb-3">
                          <input
                            placeholder=""
                            disabled
                            type="number"
                            name="Member.familyMembers"
                            onChange={handleChange}
                            value={values?.Member?.familyMembers}
                            className="form-control"
                          />
                        </div>
                        <div className="text-danger">
                          {errors?.Member?.familyMembers}
                        </div>
                      </Col>

                      <Col md="2">
                        <label>IVA</label>
                        <div className="input-group mb-3">
                          <select
                            class="form-control"
                            disabled
                            name="Member.ivaTax"
                            onChange={handleChange}
                            value={values?.Member?.ivaTax}
                          >
                            <option>Seleccione</option>
                            <option value="1">GRAVADO</option>
                            <option value="2">EXENTO</option>
                          </select>
                        </div>
                        <div className="text-danger">
                          {errors?.Member?.ivaTax}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Row>
                          <Col md="6">
                            <label>Permiso de acceso</label>
                            <div className="input-group mb-3">
                              <select
                                class="form-control"
                                name="Member.accessLevel"
                                onChange={handleChange}
                                value={values?.Member?.accessLevel}
                                // disabled={values?.Member?.accessLevel == "100"}
                              >
                                <option value="0">SIN VALIDAR</option>
                                <option value="1">VALIDADO</option>
                                <option value="2">ACCESO TURNOS</option>
                                <option value="3">ACCESO LABORATORIO</option>
                                <option value="8">ACCESO FACTURAS</option>
                                <option value="16">ACCESO EMPRESAS</option>
                                <option value="100">
                                  USUARIO DADO DE BAJA
                                </option>
                                <option value="1024">OPERADOR</option>
                                <option value="2048">ADMINISTRADOR</option>
                                <option value="999">SUPERADMIN</option>
                              </select>
                            </div>
                            <div className="text-danger">
                              {errors?.Member?.accessLevel}
                            </div>
                          </Col>
                          <Col md="6">
                            <label>Área de acceso</label>
                            <div className="input-group mb-3">
                              <select
                                class="form-control"
                                name="Member.sectorLevel"
                                onChange={handleChange}
                                value={values?.Member?.sectorLevel}
                              >
                                <option value="0">NONE</option>
                                <option value="1">SECTOR 1</option>
                                <option value="2">SECTOR 2</option>
                                <option value="4">SECTOR 3</option>
                                <option value="8">SECTOR 4</option>
                                <option value="2048">ALL</option>
                              </select>
                            </div>
                            <div className="text-danger">
                              {errors?.Member?.sectorLevel}
                            </div>
                          </Col>

                          <Col md="6">
                            <label>ID de SAP</label>
                            <div className="input-group mb-3">
                              <input
                                placeholder=""
                                type="text"
                                disabled
                                name="Member.idSap"
                                onChange={handleChange}
                                value={values?.Member?.idSap}
                                className="form-control"
                              />
                            </div>
                            {/* <div className="text-danger">{errors?.Member?.idSap}</div> */}
                          </Col>

                          <Col md="6">
                            <label>ID de TEMP SAP</label>
                            <div className="input-group mb-3">
                              <input
                                placeholder=""
                                type="text"
                                disabled
                                name="Member.idTemp"
                                onChange={handleChange}
                                value={values?.Member?.idTemp}
                                className="form-control"
                              />
                            </div>
                            {/* <div className="text-danger">{errors?.Member?.idSap}</div> */}
                          </Col>

                          <BotonDeEnviarEmail values={values} />
                        </Row>
                      </Col>

                      <Col md="6" style={{ fontSize: "15px" }}>
                        <Table striped bordered hover condensed>
                          <thead>
                            <tr>
                              <th>Tipo teléfono</th>
                              <th>Fecha cambio</th>
                              <th>Teléfono anterior</th>
                              <th>Teléfono vigente</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values?.PhoneHistory?.slice(0, 5).map(
                              (item, indice) => (
                                <tr>
                                  <td>{item.type}</td>
                                  <td>{item.changeDate}</td>
                                  <td>{item.lastNumber}</td>
                                  <td>{item.number}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2" className="mt-31">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                        >
                          <i className="fa fa-save mr-2"></i>Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModificarUsuario);
